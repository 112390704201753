<template>
  <template>
    <Dialog
      v-model:visible="displayModal"
      :breakpoints="{'1300px': '100vw'}" :style="{width: '50vw'}"
      :modal="true"
    >
      <template #header>
        <div style="color:#1c3faa;">
          <h4>
            <i class="pi pi-tag" style="font-size: 1.5rem;margin-right:10px"></i>
            <strong style="font-size:20px">Asignar Cajas</strong>
          </h4>
        </div>
      </template>
      <div class="grid lg:grid-cols-12 xl:grid-cols-12 grid-cols-1 gap-2">
        <div class="col-span-6">
          <span class="font-bold text-xs">Cod. Medicamento: </span><br>
          <p class="text-xs">{{ dataMx.ItemCode }} - {{ dataMx.ItemName }}</p>
        </div>
        <div class="flex lg:flex-col col-span-2">
          <span class="font-bold text-xs mr-2">Cantidad Disponible: </span>
          <p class="text-xs">{{ $h.formatNumber(onQuantity) }}</p>
        </div>
        <div class="flex gap-2 col-span-4 justify-end">
          <Badge v-if="dataMx.ReqMarca" :value="`Req. marca`"
                 class=" bg-red-300 text-red-700 text-xxs h-4 capitalize"/>
          <Badge v-if="dataMx.Controlado" value="Controlado"
                 class=" bg-green-300 text-green-700 text-xxs h-4 capitalize"></Badge>
          <Badge v-if="dataMx.Regulado" value="Regulado"
                 class=" bg-blue-300 text-red-blue text-xxs h-4 capitalize"></Badge>
          <Badge v-if="dataMx.cadenaFrio" value="Cadena Frio"
                 class="bg-blue-800 text-blue text-xxs h-4"></Badge>
        </div>
      </div>
      <div class="pt-2 gap-2 grid grid-cols-1 xl:grid-cols-8 lg:grid-cols-10" v-if="!readOnly">
        <div class="col-span-4">
          <span class="font-bold text-xs w-full">Cajas: </span><br>
          <Dropdown
            optionValue="indice"
            v-model="asignObject.numCaja"
            :options="Boxes"
            :optionLabel="labelBoxes"
            class="rounded-xs border-gray-300 w-full text-xs p-inputtext-xs"
            panelClass="text-xxs"
            inputClass="p-inputtext-xxs text-xxs"
            :placeholder="Boxes.length > 0 ? 'Seleccione una caja...' : 'Agregue al menos una caja'"
          />
          <br>
          <MessageError class="text-xs" :text="errors.numCaja"/>
        </div>
        <div>
          <span class="font-bold text-xs w-full">Cantidad: </span><br>
          <InputNumber inputId="integeronly" class="text-xs p-inputtext-xs w-full" :max="onQuantity" :min="1"
                       v-model="asignObject.sendQuantity"/>
          <br>
          <MessageError class="text-xs" :text="errors.sendQuantity"/>
        </div>
        <div v-if="dataMx.cadenaFrio">
          <span class="font-bold text-xs w-full">Temperatura: </span><br>
          <InputNumber inputId="integeronly" @blur="validateTemp($event)" suffix="℃"
                       class="text-xs p-inputtext-xs w-full" v-model="asignObject.temperaturaSalida"/>
          <br>
          <MessageError class="text-xs" :text="errors.temperaturaSalida"/>
        </div>
        <div v-else></div>
        <div class="col-span-2 items-end flex justify-end">
          <div>
            <Button icon="pi pi-plus-circle" label="Agregar" @click="addAsign" :disabled="onQuantity === 0"
                    class="p-button-success p-button-xs text-xs p-inputtext-xs"/>
          </div>
        </div>
      </div>
      <div class="pt-4 pb-4">
        <DataTable
          :value="asign"
          class="p-datatable-sm text-xxs"
          showGridlines
          responsiveLayout="scroll"
          editMode="row"
          @row-edit-save="onRowEditSave"
          v-model:editingRows="editingRows"
          @row-edit-cancel="onRowEditCancel"
        >
          <template #empty>
            No hay datos disponibles para mostrar.
          </template>
          <Column field="nombre" header="Nombre" style="min-width:20rem" bodyStyle="text-align:center">
            <template #body="{data}">
              <div>
                {{ findBoxAsign(data) }}
              </div>
            </template>
            <template #editor="{data}">
              <div>
                {{ findBoxAsign(data) }}
              </div>
            </template>
          </Column>
          <Column field="cantidad" header="Cantidad" style="max-width:5rem" bodyStyle="text-align:center">
            <template #body="slotProps">
              <div>
                {{ $h.formatNumber(slotProps.data.cantidad) }}
              </div>
            </template>
            <template #editor="slotProps">
              <div>
                <div class="flex items-center justify-center">
                  <InputNumber inputId="integeronly" class="text-xxs p-inputtext-xs w-full"
                               :min="1" v-model="slotProps.data.cantidad"/>
                </div>
              </div>
            </template>
          </Column>
          <Column field="temperaturaSalida" header="Temperatura salida" style="max-width:5rem"
                  headerStyle="justify-content: center;"
                  bodyStyle="justify-content: center;text-align: center" v-if="dataMx.cadenaFrio">
            <template #body="{data}">
              <div>
                {{ $h.formatNumber(data.tempSalida) }}°
              </div>
              <div class="flex items-center justify-center"
                   v-if="data.tempSalida < 1 || data.tempSalida > 8">
                <Badge value="Fuera de rango" severity="danger" class="text-xxs h-4"
                       v-tooltip="{ value: `La temperatura debe encontrarse entre 1° y ${data.rangoTemperatura}°`, disabled: false }"/>
              </div>
            </template>
            <template #editor="{data}">
              <div>
                <div class="flex items-center justify-center">
                  <InputNumber inputId="integeronly" @blur="validateTemp($event)" class="text-xxs p-inputtext-xs w-full"
                               :min="1" suffix="℃"
                               v-model="data.tempSalida"/>
                </div>
                <div class="flex items-center justify-center mt-2"
                     v-if="data.tempSalida < 1 || data.tempSalida > 8">
                  <Badge value="Fuera de rango" severity="danger" class="text-xxs h-4"
                         v-tooltip="{ value: `La temperatura debe encontrarse entre 1° y ${data.rangoTemperatura}°`, disabled: false }"/>
                </div>
              </div>
            </template>
          </Column>
          <Column header="Acciones" :rowEditor="true" style="max-width:10rem" bodyStyle="text-align:center" v-if="!readOnly">
            <template #body="slotProps">
              <div>
                <Button icon="pi pi-pencil" @click="editingRows = [slotProps.data]"
                        class="p-button-rounded p-button-text p-button-plain" v-tooltip="'Editar'"/>
                <Button icon="pi pi-minus-circle" @click="deleteAsign(slotProps)"
                        class="p-button-rounded p-button-text p-button-plain" v-tooltip="'Eliminar'"/>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
      <div class="flex justify-center mt-2"  v-if="!readOnly">
        <Button icon="pi pi-check-square" label="Confirmar" class="p-button-outlined p-button-sm" @click="onSubmit"/>
      </div>
    </Dialog>
  </template>
</template>

<script>
import { computed, ref } from 'vue'
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import Swal from 'sweetalert2'

export default {
  name: 'modalAsignBoxes',
  props: {
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const displayModal = ref(false)
    const editingRows = ref([])
    const asign = ref([])
    const Boxes = ref([])
    const dataMx = ref({})
    const schema = yup.object({
      numCaja: yup.number().required('El campo es requerido').label('Número de caja seleccionada'),
      sendQuantity: yup.number().required('El campo es requerido').label('Cantidad a enviar'),
      cadenaFrio: yup.boolean().label('Cadena de frio de la caja'),
      temperaturaSalida: yup
        .number().when('cadenaFrio', {
          is: true,
          then: yup.number().required('El campo es requerido')
        })
    })
    const { errors, values: asignObject, handleSubmit, handleReset } = useForm({
      validationSchema: schema
    })
    const onQuantity = computed(() => {
      const resultCantidad = asign.value.reduce((total, current) => {
        return total + current.cantidad
      }, 0)
      return dataMx.value.lotes.reduce((acc, val) => acc + val.Quantity, 0) - resultCantidad
    })

    /* methods */
    const labelBoxes = (option) => {
      return `${option.nombre}
      - Tipo: ${option.tipo === 1 ? 'CADENA FRIO' : 'N/A'}
      - Temperatura: ${option.rangoTemp === 1 ? 'MAYOR DE 1° & MENOR O IGUAL A 8°'
        : option.rangoTemp === 2 ? 'MENOR DE 1° & MENOR O IGUAL A -5°'
          : option.rangoTemp === 3 ? 'MENOR DE 1° & MENOR O IGUAL A -25°' : 'N/A'}`
    }
    const openAsingModalBoxes = (datosEnvio, boxes, asignBoxes) => {
      handleReset()
      let filterBox = []
      if (datosEnvio.cadenaFrio) {
        filterBox = boxes.filter((x) => x.tipo === 1)
      } else {
        filterBox = boxes.filter((x) => x.tipo !== 1)
      }
      asignObject.cadenaFrio = datosEnvio.cadenaFrio
      asign.value = asignBoxes
      dataMx.value = datosEnvio
      Boxes.value = filterBox
      displayModal.value = true
    }
    const onSubmit = () => {
      if (editingRows.value.length >= 1) {
        Swal.fire({
          icon: 'info',
          text: 'No se permite confirmar la asignación de cajas cuando se está editando un registro.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        return
      }
      displayModal.value = false
    }
    const deleteAsign = ({ index }) => {
      Swal.fire({
        icon: 'question',
        title: '¿Esta seguro/a?',
        text: '¿Desea eliminar esta asigación?',
        showConfirmButton: true,
        focusCancel: true,
        showCancelButton: true,
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'No, cancelar'
      }).then((resp) => {
        if (resp.isConfirmed) {
          asign.value.splice(index, 1)
          Swal.fire({
            icon: 'success',
            title: 'Éxito',
            text: 'Asignación eliminada correctamente',
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          })
        }
      })
    }
    const validateTemp = (evnt) => {
      if (evnt.target.value) {
        const valueTemp = parseInt(evnt.target.value.replace(/[.,a-zA-Z$]/g, ''))
        if (valueTemp > 8 || valueTemp < 1) {
          Swal.fire({
            icon: 'info',
            text: 'El medicamento ingresado esta por fuera del rango de temperatura.',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true
          })
        }
      }
    }
    const onRowEditSave = ({ data, index }) => {
      if (asign.value.reduce((acc, val) => acc + val.cantidad, 0) > dataMx.value.Quantity) {
        Swal.fire({
          icon: 'info',
          text: 'La cantidad asignada no puede exceder la cantidad disponible para este medicamento..',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        asign.value[index].cantidad = data.cantOriginal
      }
      asign.value[index].cantOriginal = data.cantidad
      asign.value[index].tempSalidaOri = data.tempSalida
    }
    const onRowEditCancel = ({ data, index }) => {
      asign.value[index].cantidad = data.cantOriginal
      asign.value[index].tempSalida = data.tempSalidaOri
    }
    const findBoxAsign = (data) => {
      const findBox = Boxes.value.find((x) => x.indice === data.indice)
      if (findBox) {
        return `${findBox.nombre} - Tipo: ${findBox.tipo === 1 ? 'CADENA FRIO' : 'N/A'}
          Temperatura: ${findBox.rangoTemp === 1 ? 'MAYOR DE 1° & MENOR O IGUAL A 8°'
          : findBox.rangoTemp === 2 ? 'MENOR DE 1° & MENOR O IGUAL A -5°'
            : findBox.rangoTemp === 3 ? 'MENOR DE 1° & MENOR O IGUAL A -25°' : 'N/A'}`
      }
    }
    const addAsign = handleSubmit((values) => {
      if (!values) return
      const findBox = Boxes.value.find((x) => x.indice === asignObject.numCaja)
      if (!dataMx.value.cadenaFrio && Boxes.value.some((x) => x.tipo === 1 && x.indice === findBox.indice)) {
        Swal.fire({
          icon: 'info',
          text: 'En esta caja solo pueden ir medicamentos con cadena de frio.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        return
      }
      // si el medicamento tiene cadena de frío y se intenta meter en una caja normal
      if (dataMx.value.cadenaFrio && Boxes.value.some((x) => x.tipo === 2 && x.indice === findBox.indice)) {
        Swal.fire({
          icon: 'info',
          text: 'En esta caja solo pueden ir medicamentos sin cadena de frio.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        return
      }
      const rangoTemp = findBox.rangoTemp === 1 ? 8 : findBox.rangoTemp === 2 ? -5 : findBox.rangoTemp === 3 ? -25 : 'N/A'
      const existBox = asign.value.find((a) => a.indice === findBox.indice)
      if (existBox) {
        Swal.fire({
          icon: 'info',
          text: 'Esta caja ya fue agregada en la asignación y no se puede repetir, por favor revise.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        return
      }
      asign.value.push({
        indice: findBox.indice,
        cantidad: asignObject.sendQuantity,
        cantOriginal: asignObject.sendQuantity,
        tempSalida: asignObject.temperaturaSalida ?? null,
        tempSalidaOri: asignObject.temperaturaSalida ?? null,
        rangoTemperatura: rangoTemp
      })
      handleReset()
      asignObject.cadenaFrio = dataMx.value.cadenaFrio
    })
    return {
      displayModal,
      asign,
      openAsingModalBoxes,
      onSubmit,
      Boxes,
      dataMx,
      asignObject,
      handleSubmit,
      handleReset,
      errors,
      addAsign,
      labelBoxes,
      onQuantity,
      deleteAsign,
      validateTemp,
      editingRows,
      onRowEditSave,
      onRowEditCancel,
      findBoxAsign
    }
  }
}
</script>

<style scoped>
::v-deep(.p-column-header-content) {
  justify-content: center;
}

:global(.swal2-container) {
  z-index: 1000000 !important
}

::v-deep(.p-tooltip) {
  width: 10rem;
}

::v-deep(.p-inputnumber) {
  width: 3rem;
}

::v-deep(.p-inputnumber-input) {
  width: 3rem;
}

</style>
